<template>
    <CommonModalComponent id="messagesModal" ref="messagesModal" size="xl" :no-footer="true">
        <div>
            <!-- button actions -->
            <div class="select-drivers btn-group">
                <!-- filter for all or actual terminal -->
                <div class="dropdown pe-2">
                    <button
                        v-if="store.terminal"
                        id="dropdownMenuButtonFilteringRide"
                        class="btn btn-secondary dropdown-toggle me-2"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        {{ lastFilter === 4 ? $t("messageBox.filtr_4") : store.terminal.PLNY_NAZEV }}
                    </button>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonFilteringRide">
                        <a v-if="store.terminal" class="dropdown-item" @click="filterRideTrip(1)">{{
                            store.terminal.PLNY_NAZEV
                        }}</a>
                        <a class="dropdown-item" @click="filterRideTrip(4)">{{ $t("messageBox.filtr_4") }}</a>
                    </div>
                </div>

                <!-- select or deselect send message for --->
                <button
                    v-if="selectedUserChat.length === 0 && toggleAllUsers"
                    type="button"
                    class="btn btn-secondary me-2"
                    @click="toggleWithRide"
                >
                    {{ toggleActiveUsers ? $t("messageBox.selectWithAllRide") : $t("messageBox.selectWithHaveRide") }}
                </button>
                <button type="button" class="btn btn-secondary me-2 px-2 py-1" @click="toggleAll">
                    {{ selectedUserChat.length === 0 ? $t("messageBox.selectAll") : $t("messageBox.deselectAll") }}
                </button>
            </div>
        </div>

        <div>
            <div class="mb-2">
                <!-- show selected user for chat -->
                <span
                    v-for="(user, index) in selectedUserChat"
                    :key="index"
                    class="badge bg-secondary m-1 tw-text-black"
                    @click="selectOne(user)"
                    >{{ user.RIDIC || user.USER }}</span
                >
                <div v-if="selectedUserChat.length === 0" class="text-center">
                    {{ $t("messageBox.notSelectedUser") }}
                </div>
            </div>

            <div class="dispo-chat border-top">
                <nav class="border-end d-flex flex-column">
                    <div class="p-2">
                        <div class="form-control-group has-success">
                            <label class="control-label" for="inputSearchDriver">{{ $t("label.search") }}</label>
                            <input id="inputSearchDriver" v-model="searchInput" type="text" class="form-control" />
                        </div>
                    </div>
                    <!-- show all user chat -->
                    <div class="overflow-auto p-2">
                        <li v-for="(user, index) in filteredUsers" :key="index" @click="selectOne(user)">
                            {{ user.RIDIC || user.USER }} ({{ user.CM }})
                            <span v-if="user.NEW_MESSAGES > 0" class="badge bg-success">{{ user.NEW_MESSAGES }}</span>
                        </li>
                    </div>
                </nav>

                <div id="dispo-chat-messages" class="dispo-chat-messages">
                    <div v-if="selectedUserChat.length === 0">
                        {{ $t("messageBox.selectOneDriver") }}
                    </div>

                    <div v-else-if="messages.length === 0">
                        {{ $t("messageBox.noMessages") }}
                    </div>

                    <div v-else class="chat">
                        <div
                            v-for="(messageData, index) in messages"
                            :key="index"
                            :class="`message ${messageData.TYPE} text-break text-wrap`"
                        >
                            {{ messageData.MESSAGE }}
                            <div v-if="messageData.SENT" class="row">
                                <small class="col-sm-6 font-italic d-block text-start">{{ messageData.USER }}</small>
                                <small :class="`col-sm-6 d-block text-end message-info-${messageData.SENT}`">{{
                                    $dayjs(messageData.SENT).format("HH:mm:ss DD.MM.YYYY")
                                }}</small>
                            </div>
                            <div v-else>
                                <CommonSpinnerComponent size="10px" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="message-box d-flex mt-3">
                <div class="input-group">
                    <textarea
                        v-model="message"
                        maxlength="1000"
                        type="text"
                        style="resize: none"
                        class="form-control"
                        :disabled="selectedUserChat.length === 0"
                    />
                    <div class="input-group-text">
                        <span id="basic-addon2" style="font-size: x-small" class="input-group-text"
                            >{{ message.length }}/1000</span
                        >
                    </div>
                </div>

                <button class="btn btn-primary ms-3" :disabled="selectedUserChat.length === 0" @click="sendMessage">
                    {{ $t("messageBox.sendButton") }}
                </button>
            </div>
        </div>
    </CommonModalComponent>
</template>

<script setup lang="ts">
import type { ChatMessageReceiving } from "~~/backend/types/socket";

import type ModalComponent from "~/components/common/ModalComponent.vue";

interface IMessage {
    DELIVERED?: string;
    ID?: number;
    ID_RECIPIENT?: number;
    ID_SENDER?: number;
    MESSAGE: string;
    READ?: string;
    SENT?: string | null;
    USER?: string;
    TYPE: "dispatcher" | "driver";
}

interface IChatUser {
    CM: string;
    DEPO: string;
    EMAIL: string;
    ID: number;
    ID_RIDICI: number;
    LANGUAGE: string;
    NEW_MESSAGES: number;
    RIDE_ID: number | null;
    RIDIC: string;
    TELEFON: string;
    USER: string;
    USERRIGHT: string;
}

const { data: authData } = useAuth();
const axios = useAxios();
const connectionStore = useConnectionStore();
const dayjs = useDayjs();
const { t } = useI18n();
const store = useStore();
const toast = useToast();

const users = ref<Array<IChatUser>>([]);
const filteredUsers = ref<Array<IChatUser>>([]);
const selectedUserChat = ref<Array<IChatUser>>([]);
const messages = ref<Array<IMessage>>([]);
const message = ref("");
const toggleActiveUsers = ref(false);
const toggleAllUsers = ref(false);
const searchInput = ref("");
const lastFilter = ref<1 | 2 | 3 | 4>(1);

const messagesModal = ref<InstanceType<typeof ModalComponent>>();
const { status: authStatus } = useAuth();

const listeners = {
    "dispo.chat.message": chatMessageListener
};

onMounted(() => {
    handleListeners("off", listeners);
    handleListeners("on", listeners);
});

watch(
    () => authStatus.value,
    (newValue) => {
        if (newValue !== "authenticated") {
            if (listeners) {
                handleListeners("off", listeners);
            }
        }
    }
);

function chatMessageListener({ message, sender }: ChatMessageReceiving<false>) {
    store.setNewMessagesCount(store.newMessagesCount + 1);
    toast.info(`${t("label.newMessageDispo")} (${sender.username}): ${message.content}`);

    if (selectedUserChat.value.length === 1 && selectedUserChat.value[0].ID === sender.id) {
        messages.value.push({
            ID_SENDER: sender.id,
            MESSAGE: message.content,
            SENT: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            TYPE: "driver",
            USER: sender.username
        });
        scrollToBottom();
    } else {
        const finding = users.value.find((user) => user.ID === sender.id);

        if (finding) {
            finding.NEW_MESSAGES++;
            filteredUsers.value = users.value.sort((a, b) => (a.NEW_MESSAGES < b.NEW_MESSAGES ? 1 : -1));
        } else {
            filterRideTrip(lastFilter.value);
        }
    }
}

// get messages
async function getMessages(id: number) {
    await axios.get<Array<IMessage>>("api/chat/" + id).then((messagesData) => {
        if (messagesData.data) {
            messages.value = messagesData.data;

            if (selectedUserChat.value[0].NEW_MESSAGES > 0) {
                store.decrementNewMessagesCount(selectedUserChat.value[0].NEW_MESSAGES);
                selectedUserChat.value[0].NEW_MESSAGES = 0;
            }

            read(id);
            scrollToBottom();
        }
    });
}

/* select/deselect users for message  */
function selectOne(user: IChatUser) {
    const found = selectedUserChat.value.findIndex((s) => s.CM === user.CM);
    if (found > -1) {
        selectedUserChat.value.splice(found, 1);
    } else {
        selectedUserChat.value.push(user);
    }

    // TODO: asi pokud bude send tak se to musi zobrazit
    if (selectedUserChat.value.length === 1) {
        getMessages(selectedUserChat.value[0].ID);
    } else {
        messages.value = [];
    }
}

// mark select deselect all
function toggleAll() {
    if (selectedUserChat.value.length === 0) {
        selectedUserChat.value = [...users.value];
    } else {
        selectedUserChat.value = [];
    }
}

// select all user who have a ride
function toggleWithRide() {
    if (!toggleActiveUsers.value) {
        users.value = users.value.filter((user) => user.RIDE_ID);
    }
    toggleActiveUsers.value = !toggleActiveUsers.value;
}

// dropdownMenuButtonFilteringRide
async function filterRideTrip(filter: 1 | 2 | 3 | 4) {
    // 1 - actual terminal
    // 2 - FROM -- not applied
    // 3 - TO -- not applied
    // 4 - ALL

    lastFilter.value = filter;

    if (store.terminal) {
        await axios
            .get<Array<IChatUser>>("api/auth/users/" + store.terminal.ST_FLAG, {
                params: { foreignDrivers: filter === 1 ? 0 : 1 }
            })
            .then((usersData) => {
                if (usersData.data) {
                    users.value = usersData.data;
                    filteredUsers.value = filterUsers(searchInput.value);
                }
                let messagesCount = 0;
                for (const user of users.value) {
                    messagesCount += user.NEW_MESSAGES;
                }
                store.setNewMessagesCount(messagesCount);
            });

        toggleAllUsers.value = false;
    }
}

// send message
function sendMessage() {
    connectionStore.socket.emit(
        "chat.message",
        {
            idRecipients: selectedUserChat.value.map((user) => user.ID),
            message: {
                content: message.value
            }
        },
        (idMessage) => {
            const sentMessages = messages.value.filter((message) => message.SENT === null);
            for (const messageObject of sentMessages) {
                messageObject.SENT = dayjs().local().format("YYYY-MM-DD HH:mm:ss");
                messageObject.ID = idMessage;
                messageObject.USER = authData.value?.user ? authData.value.user : "";
            }
        }
    );

    messages.value.push({
        ID_RECIPIENT: selectedUserChat.value[0].ID,
        MESSAGE: message.value,
        SENT: null,
        TYPE: "dispatcher"
    });

    message.value = "";
    scrollToBottom();
}

/**
 * Flag messages as read
 */
function read(id: number) {
    connectionStore.socket.emit("chat.read", id);
}

function scrollToBottom() {
    nextTick(() => {
        const chat = document.getElementById("dispo-chat-messages");
        if (chat) {
            chat.scrollTop = chat.scrollHeight;
        }
    });
}

watch(searchInput, (input: string) => {
    filteredUsers.value = filterUsers(input);
});

function filterUsers(input: string) {
    return users.value.filter(
        (user) =>
            user.CM?.toLowerCase().includes(input.toLowerCase()) ||
            user.EMAIL?.toLowerCase().includes(input.toLowerCase()) ||
            user.RIDIC?.toLowerCase().includes(input.toLowerCase()) ||
            user.TELEFON?.replace(/\s+/g, "").includes(input.replace(/\s+/g, "")) ||
            user.USER?.toLowerCase().includes(input.toLowerCase())
    );
}

onMounted(() => {
    nextTick(() => {
        messagesModal?.value?.onOpenedEvent(() => {
            filterRideTrip(1);
        });
    });
});

function showModal() {
    messagesModal.value?.showModal();
}

function closeModal() {
    messagesModal.value?.closeModal();
}

defineExpose({ showModal, closeModal });
</script>

<style lang="scss">
@import "~/assets/scss/pages/_chat";
</style>
