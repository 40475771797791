<template>
    <CommonModalComponent id="documentation" ref="documentationModal" :title="$t('label.help')" :no-footer="true">
        <ContentRendererMarkdown v-if="page" :value="page" class="docs" />
    </CommonModalComponent>
</template>

<script setup lang="ts">
import type { MarkdownParsedContent } from "@nuxt/content";

import type ModalComponent from "./ModalComponent.vue";

const documentationModal = ref<InstanceType<typeof ModalComponent>>();
const page = ref<MarkdownParsedContent | null>(null);
const { data: authData } = useAuth();
onMounted(() => {
    nextTick(() => {
        onOpenedEvent(async () => {
            const { data: posts } = await useAsyncData(() =>
                queryContent<MarkdownParsedContent>(authData.value?.language).findOne()
            );

            page.value = posts.value;
        });
    });
});

function closeModal() {
    documentationModal.value?.closeModal();
}

function showModal() {
    documentationModal.value?.showModal();
}

function onOpenedEvent(e: () => void) {
    documentationModal.value?.onOpenedEvent(e);
}

function onClosedEvent(e: () => void) {
    documentationModal.value?.onClosedEvent(e);
}

defineExpose({
    closeModal,
    showModal,
    onClosedEvent,
    onOpenedEvent
});
</script>

<style lang="scss">
.docs {
    font-size: 14px;

    h1,
    h2,
    h3 {
        font-weight: 700;
    }

    h1 {
        font-size: 22px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }
}
</style>
