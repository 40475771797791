<template>
    <CommonModalComponent
        id="VersionCheck"
        ref="versionCheckModal"
        :custom-footer="true"
        :title="$t('label.newUpdateHeader')"
        :no-close-button="true"
    >
        <div>{{ $t("label.newUpdateText") }}</div>
        <template #footer>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 px-1 py-1">
                        <button type="button" class="btn btn-success text-center" @click="reloadFrontend">
                            {{ $t("btn.yes") }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </CommonModalComponent>
</template>

<script setup lang="ts">
import { App } from "@capacitor/app";

import type ModalComponent from "~/components/common/ModalComponent.vue";

const axios = useAxios();
const connectionStore = useConnectionStore();
const { $apm } = useNuxtApp();
const store = useStore();

const showVersionChange = ref(false);
const checkInterval = ref<number | null>(null);

const versionCheckModal = ref<InstanceType<typeof ModalComponent>>();

const listeners = {
    "app.version": appVersionListener
};

function appVersionListener(version: string) {
    const cookieVersion = window.localStorage.getItem("version");
    if (cookieVersion && cookieVersion !== version && process.env.NODE_ENV !== "development") {
        showVersionChange.value = true;
    }
}

onMounted(() => {
    handleListeners("on", listeners);

    if (process.env.NODE_ENV !== "development") {
        const cookieVersion = window.localStorage.getItem("version");
        if (!cookieVersion) {
            window.localStorage.setItem("version", store.appVersion);
        } else if (cookieVersion !== store.appVersion) {
            window.localStorage.setItem("version", store.appVersion);
            reloadFrontend();
        }

        if (!connectionStore.socket.connected) {
            versionCheckInterval();
            checkVersion();
        }

        App.addListener("resume", () => {
            checkVersion();
        });
    }
});

onUnmounted(() => {
    handleListeners("off", listeners);
});

watch(showVersionChange, () => {
    if (showVersionChange.value === true) {
        versionCheckModal.value?.showModal();
    }
    showVersionChange.value = false;
});

watch(
    () => connectionStore.socket.connected,
    (connected) => {
        if (connected && process.env.NODE_ENV !== "development") {
            handleListeners("off", listeners);
            handleListeners("on", listeners);

            if (checkInterval.value) {
                clearInterval(checkInterval.value);
                checkInterval.value = null;
            }
        } else {
            versionCheckInterval();
        }
    },
    { deep: true }
);

async function reloadFrontend() {
    await clearCache().catch((e) => {
        $apm.captureError(e);
        location.reload();
    });
    location.reload();
}

async function clearCache() {
    if ("caches" in window) {
        const cacheNames = await caches.keys();
        await Promise.all(
            cacheNames.map(async (cacheName) => {
                await caches.delete(cacheName);
            })
        );
    }
}

function checkVersion() {
    axios
        .get("/api/frontendVersion")
        .then((version) => {
            if (version.data > store.appVersion) {
                showVersionChange.value = true;
            }
        })
        .catch((e) => {
            $apm.captureError(e);
            // location.reload();
        });
}

function versionCheckInterval() {
    if (!checkInterval.value) {
        checkInterval.value = window.setInterval(
            () => {
                checkVersion();
            },
            10 * 60 * 1000
        );
    }
}

function showModal() {
    versionCheckModal.value?.showModal();
}
defineExpose({
    showModal
});
</script>
