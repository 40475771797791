// plugins/apm.client.ts
import { defineNuxtPlugin } from "#app";
import { init as initApm } from "@elastic/apm-rum";

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const baseUrl =
        !config.public.baseUrl || config.public.baseUrl === "" ? window.location.origin : config.public.baseUrl; // Ensure config.public.baseUrl is of type string

    const apm = initApm({
        serverUrl: `${baseUrl}/api/elastic/`, // Use baseUrl in the template literal expression
        serviceName: "MTrucks-frontend",
        environment: process.env.NODE_ENV,
        serviceVersion: config.public.packageVersion as string, // Cast config.public.packageVersion to string
        breakdownMetrics: true,
        pageLoadSampled: true,
        propagateTracestate: true
    });

    const router = useRouter();

    if (router) {
        router.beforeEach((to, _from, next) => {
            apm.startTransaction(to.name?.toString(), "route-change", { managed: true });
            next();
        });

        router.afterEach(() => {
            apm.getCurrentTransaction()?.end();
        });
    }

    return {
        provide: {
            apm
        }
    };
});
