<template><div /></template>

<script setup lang="ts">
import { App, type URLOpenListenerEvent } from "@capacitor/app";
import { Toast } from "@capacitor/toast";

const dayjs = useDayjs();

const { t } = useI18n();

const { $apm } = useNuxtApp();

const lastBackPressed = ref<Date | undefined>();
const store = useStore();
const router = useRouter();
const lastPaused = ref<Date | undefined>();

onMounted(() => {
    addMobileHooks();
    App.addListener("pause", () => {
        lastPaused.value = new Date();
    });
    App.addListener("resume", () => {
        if (
            (router.currentRoute.value.name === "index" || router.currentRoute.value.name === "driver-cmr") &&
            store.activeRide?.ID &&
            dayjs(lastPaused.value).add(120, "seconds") <= dayjs(new Date())
        ) {
            store.loadActiveRide(true);
        }
    });
});

async function addMobileHooks() {
    await App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        const parsedUrl = new URL(event.url);

        const searchParams = new URLSearchParams(parsedUrl.search);
        const query = Object.fromEntries(searchParams.entries());

        return navigateTo({
            path: parsedUrl.pathname,
            query
        });
    });
    await App.addListener("backButton", () => {
        if (
            lastBackPressed.value &&
            lastBackPressed.value.setMilliseconds(lastBackPressed.value.getMilliseconds() + 800).valueOf() >=
                new Date().valueOf()
        ) {
            App.exitApp().catch((e) => {
                $apm.captureError(e);
            });
        } else {
            lastBackPressed.value = new Date();
            Toast.show({ text: t("app.native.backButton.tapAgain").toString() }).catch((e) => {
                $apm.captureError(e);
            });
        }
    });
}
</script>
