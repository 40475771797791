<template>
    <Teleport to="body">
        <div :id="props.id" class="modal fade" role="dialog">
            <div :class="['modal-dialog', 'modal-' + props.size, props.scrollable ? 'modal-dialog-scrollable' : '']">
                <div class="modal-content">
                    <div v-if="props.loading">
                        <div id="loadingOverlay">
                            <div class="cv-spinner">
                                <CommonSpinnerComponent />
                            </div>
                        </div>
                    </div>
                    <div class="modal-header">
                        <h5 class="modal-title">{{ props.title }}</h5>
                        <button
                            v-if="!props.noCloseButton"
                            type="button"
                            :aria-label="$t('btn.close')"
                            class="btn-close"
                            @click="closeModal()"
                        />
                    </div>
                    <div :class="`modal-body ${props.noBorder ? 'p-0' : ''}`">
                        <slot />
                    </div>
                    <div v-if="!props.customFooter && !props.noFooter" class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal()">
                            {{ $t("btn.close") }}
                        </button>
                        <button type="button" class="btn btn-primary" @click="save">
                            {{ $t("btn.save") }}
                        </button>
                    </div>
                    <div v-if="props.customFooter && !props.noFooter" class="modal-footer">
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
type Props = {
    title?: string;
    customFooter?: boolean;
    noFooter?: boolean;
    id: string;
    noCloseButton?: boolean;
    noBorder?: boolean;
    size?: string;
    scrollable?: boolean;
    loading?: boolean;
    save?: () => void;
};

const props = withDefaults(defineProps<Props>(), {
    title: "",
    customFooter: false,
    noFooter: false,
    noCloseButton: false,
    noBorder: false,
    scrollable: true,
    size: "md",
    loading: false,
    save: () => {}
});
const { $bootstrap, $apm } = useNuxtApp();

const modalHtmlElement = ref<HTMLElement | null>(null);
const modalObject = ref<bootstrap.Modal | null>(null);

onMounted(() => {
    modalHtmlElement.value = document.getElementById(props.id.toString());
    if (modalHtmlElement.value) {
        modalObject.value = new $bootstrap.Modal(modalHtmlElement.value, {
            backdrop: props.noCloseButton ? "static" : true,
            keyboard: false,
            focus: !props.noCloseButton
        });
    }
});

onUnmounted(() => {
    modalObject.value?.dispose();
});

function closeModal() {
    try {
        modalObject.value?.hide();
    } catch (e) {
        $apm.captureError(e as Error);
    }
}

function showModal() {
    modalObject.value?.show();
}

function onOpenedEvent(e: () => void) {
    modalHtmlElement.value?.addEventListener("shown.bs.modal", () => {
        e();
    });
}

function onClosedEvent(e: () => void) {
    modalHtmlElement.value?.addEventListener("hidden.bs.modal", () => {
        e();
    });
}

defineExpose({ onClosedEvent, onOpenedEvent, closeModal, showModal });
</script>

<style scoped>
#loadingOverlay {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.cv-spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
