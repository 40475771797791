<template>
    <CommonModalComponent
        id="viewOpenRidesModal"
        ref="viewOpenRidesModal"
        :title="$t('label.unfinishedrides')"
        :custom-footer="true"
        :scrollable="true"
        size="lg"
    >
        <div v-if="ShowInputBoxForCM">
            <div class="form-control-group">
                <label
                    >{{ $t("dispo.users.id_email_driver") }}/CMR/{{ $t("i18n.report.confirmation.release") }}/{{
                        $t("label.container")
                    }}</label
                >
                <input v-model="CM" type="test" class="form-control" Placeholder="" />
            </div>
            <div v-if="SearchNotFound">
                <p class="text-danger">{{ $t("errors.containers.5") }}</p>
            </div>
            <button class="btn btn-primary btn-sm" @click="SearchUserByCM">{{ $t("label.search") }}</button>
        </div>

        <div v-if="OpenRides.length > 0">
            <hr />
            {{ $t("label.unfinishedridesapp") }}
            <div class="mt-4">
                <ul v-if="OpenRides.length > 0">
                    <li v-for="OpenRide in OpenRides" :key="OpenRide.ID">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12">
                                    <input v-model="OpenRidescheckedID" type="checkbox" :value="OpenRide.ID" />
                                    {{ OpenRide.CMR ? OpenRide.ID : OpenRide.FULLNAME + " - " + OpenRide.USER }}
                                </div>
                                <div v-if="OpenRide.CMR" class="col-sm-12">
                                    {{ $t("label.cmr") }}: {{ OpenRide.CMR }}
                                </div>
                                <div v-else class="col-sm-12">{{ $t("cmr.label.releaseNo") }}: {{ OpenRide.RPC }}</div>
                                <div class="col-sm-12">{{ $t("label.container") }}: {{ OpenRide.CONTAINER }}</div>
                                <div v-if="OpenRide.DELIVERY_DATE" class="col-sm-12">
                                    {{ $t("label.deliveryDate") }}:
                                    {{ $dayjs(OpenRide.DELIVERY_DATE).format("HH:mm DD.MM.YYYY") }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="NoneRides.length > 0">
            <hr />
            {{ $t("label.unfinishedridesnpp") }}
            <div class="mt-4">
                <ul v-if="NoneRides.length > 0">
                    <li v-for="NoneRide in NoneRides" :key="NoneRide.ID">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12">
                                    <input v-model="NoneRidescheckedID" type="checkbox" :value="NoneRide.ID" />
                                    {{ NoneRide.ID }}
                                </div>
                                <div class="col-sm-12">{{ $t("label.cmr") }}: {{ NoneRide.CMR }}</div>
                                <div class="col-sm-12">{{ $t("label.container") }}: {{ NoneRide.CONTAINER }}</div>
                                <div v-if="NoneRide.PP_TIME && NoneRide.PP_DATE" class="col-sm-12">
                                    {{ $t("label.deliveryDate") }}: {{ NoneRide.PP_TIME }}
                                    {{ $dayjs(NoneRide.PP_DATE).format("YYYY-MM-DD") }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="OpenRides.length > 0 || NoneRides.length > 0">
            <hr />
            {{ $t("actions.header") }}: <i> {{ $t("label.selectclosecmr") }} </i>
        </div>
        <template #footer>
            <button
                v-if="OpenRidescheckedID.length > 0 || NoneRidescheckedID.length > 0"
                type="button"
                class="btn btn-primary"
                @click="setData"
            >
                {{ $t("btn.save") }}
            </button>
            <button type="button" class="btn btn-secondary" @click="viewOpenRidesModal?.closeModal()">
                {{ $t("btn.close") }}
            </button>
        </template>
    </CommonModalComponent>
</template>

<script setup lang="ts">
import type ModalComponent from "~/components/common/ModalComponent.vue";

const { $apm } = useNuxtApp();
const axios = useAxios();
const viewOpenRidesModal = ref<InstanceType<typeof ModalComponent>>();

interface INoneRide {
    CMR: string;
    CONTAINER: string;
    ID: number;
    PP_DATE: string;
    PP_TIME: string;
}

interface IOpenRide {
    CMR: string;
    CONTAINER: string;
    DELIVERY_DATE: string;
    FULLNAME: string;
    ID: number;
    RPC: string | null;
    USER: string;
}

const DriverCm = ref<string>("");
const OpenRides = ref<Array<IOpenRide>>([]);
const NoneRides = ref<Array<INoneRide>>([]);
const OpenRidescheckedID = ref<Array<number>>([]);
const NoneRidescheckedID = ref<Array<number>>([]);
const ShowInputBoxForCM = ref(false);
const CM = ref("");
const SearchNotFound = ref(false);

onMounted(() => {
    nextTick(() => {
        viewOpenRidesModal.value?.onOpenedEvent(() => {
            initOpenRides();
            if (DriverCm.value) {
                ShowInputBoxForCM.value = false;
                getData(DriverCm.value);
            } else {
                ShowInputBoxForCM.value = true;
            }
        });
    });
});

/* search for unfinished rides for drivers, CM passed by input  */
function SearchUserByCM() {
    if (CM.value) {
        getData(CM.value.toUpperCase());
    }
}

/* get unfinished rides */
function getData(CM: string) {
    OpenRides.value = [];
    NoneRides.value = [];
    axios.get("api/ride/" + CM + "/openridesbydriver").then((rides) => {
        OpenRides.value = rides.data.openrides;

        NoneRides.value = rides.data.nonerides;

        OpenRides.value.length > 0 || NoneRides.value.length > 0
            ? (SearchNotFound.value = false)
            : (SearchNotFound.value = true);
    });
}

/* set unfinished rides, close them   */
function setData() {
    axios
        .post("api/ride/openridesbydriver", {
            OpenTripToClose: OpenRidescheckedID.value,
            NoneTripToClose: NoneRidescheckedID.value
        })
        .then(() => {
            getData(CM.value.toUpperCase());
        })
        .catch((e) => {
            $apm.captureError(e);
        });
}

/* set clear data */
function initOpenRides() {
    ShowInputBoxForCM.value = false;
    CM.value = "";
    OpenRides.value = [];
    NoneRides.value = [];
    NoneRidescheckedID.value = [];
    OpenRidescheckedID.value = [];
}

function showModal() {
    viewOpenRidesModal.value?.showModal();
}

function closeModal() {
    viewOpenRidesModal.value?.closeModal();
}

defineExpose({
    showModal,
    closeModal
});
</script>
