<template><div /></template>

<script setup lang="ts">
import { Capacitor } from "@capacitor/core";
import { Dialog } from "@capacitor/dialog";
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";

const { t } = useI18n();

const { $apm } = useNuxtApp();
const { token } = useAuthState();
onMounted(async () => {
    await startGeolocation();
    await checkBatteryOptimizations();
});

onUnmounted(async () => {
    await BackgroundGeolocation.stop();
});

async function checkBatteryOptimizations() {
    if (Capacitor.getPlatform() === "android") {
        let isIgnoring = await BackgroundGeolocation.deviceSettings.isIgnoringBatteryOptimizations();
        console.log("isNotIgnoring", isIgnoring);
        let isIgnoringBatteryOptimizationsCount = 0;
        while (!isIgnoring) {
            console.log("WhileIsNotIgnoring", isIgnoring);
            const result = await BackgroundGeolocation.deviceSettings.showIgnoreBatteryOptimizations();

            console.log("result", result);
            isIgnoringBatteryOptimizationsCount++;
            if (isIgnoringBatteryOptimizationsCount > 3) {
                isIgnoring = true;
            } else {
                isIgnoring = false;
            }
            console.log("isNotIgnoring", isIgnoring);
            isIgnoring = await BackgroundGeolocation.deviceSettings.isIgnoringBatteryOptimizations();
            console.log("isNotIgnoring", isIgnoring);
            if (!isIgnoring) {
                return;
            }
            // It's your responsibility to instruct the user what exactly
            // to do here, perhaps with a Confirm Dialog:

            await Dialog.alert({
                title: t("app.native.batteryOptimization.title").toString(),
                message: t("app.native.batteryOptimization.message").toString()
            })
                .then(async () => {
                    // User clicked [Confirm] button.  Execute the redirect to settings screen:
                    await BackgroundGeolocation.deviceSettings.show(result);
                    isIgnoring = await BackgroundGeolocation.deviceSettings.isIgnoringBatteryOptimizations();
                })
                .catch((e) => {
                    $apm.captureError(e);
                    isIgnoringBatteryOptimizationsCount++;
                    if (isIgnoringBatteryOptimizationsCount > 3) {
                        isIgnoring = true;
                    } else {
                        isIgnoring = false;
                    }
                });
        }
    }
}

async function showLocationConfirm() {
    await Dialog.alert({
        title: t("app.native.location.title").toString(),
        message: t("app.native.location.message").toString()
    });
}

async function startGeolocation() {
    await BackgroundGeolocation.ready({
        url: `${window.location.origin}/api/ride/location`,
        // Geolocation Config
        locationAuthorizationRequest: "Always",
        desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
        // Application config
        showsBackgroundLocationIndicator: true,
        disableLocationAuthorizationAlert: true,
        debug: false, // <-- enable this hear sounds for background-geolocation life-cycle.
        logLevel: BackgroundGeolocation.LOG_LEVEL_WARNING,
        activityType: BackgroundGeolocation.ACTIVITY_TYPE_OTHER,
        authorization: { strategy: "JWT", accessToken: token.value?.split(" ")[1] || "" },
        backgroundPermissionRationale: {
            message: t("app.native.location.message").toString(),
            title: t("app.native.location.title").toString(),
            positiveAction: t("btn.yes").toString(),
            negativeAction: t("btn.no").toString()
        },
        locationAuthorizationAlert: {
            instructions: t("app.native.location.message").toString(),
            titleWhenNotEnabled: t("app.native.location.title").toString(),
            titleWhenOff: t("app.native.location.title").toString(),
            settingsButton: t("btn.yes").toString(),
            cancelButton: t("btn.no").toString()
        },
        notification: {
            title: t("app.native.location.backgroundTitle").toString(),
            text: t("app.native.location.backgroundMessage").toString()
        },
        elasticityMultiplier: 8,
        distanceFilter: 20,
        preventSuspend: true,
        foregroundService: true
    }).then(async () => {
        console.log("BackgroundGeolocation ready");

        const providerState = await BackgroundGeolocation.getProviderState();
        console.log("- Provider state: ", providerState);

        // let permissionsActive = false;
        // while (!permissionsActive) {
        //     try {
        //         const status = await BackgroundGeolocation.requestPermission();
        //         if (status === BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS) {
        //             permissionsActive = true;
        //         } else {
        //             await showLocationConfirm();
        //             await NativeSettings.open({
        //                 optionAndroid: AndroidSettings.ApplicationDetails,
        //                 optionIOS: IOSSettings.LocationServices
        //             });
        //         }
        //     } catch (status) {
        //         if (status === 1 || status === 2) {
        //             await showLocationConfirm();
        //             await NativeSettings.open({
        //                 optionAndroid: AndroidSettings.ApplicationDetails,
        //                 optionIOS: IOSSettings.LocationServices
        //             });
        //         }
        //     }
        // }

        await BackgroundGeolocation.start()
            .then((data) => {
                console.log("BackgroundGeolocation started", data);
            })
            .catch(async (e) => {
                console.error("BackgroundGeolocation start error", e);
                let permissionsActive = false;
                while (!permissionsActive) {
                    try {
                        const status = await BackgroundGeolocation.requestPermission();
                        if (status === BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS) {
                            permissionsActive = true;
                        } else {
                            await showLocationConfirm();
                            await NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.LocationServices
                            });
                        }
                    } catch (status) {
                        if (status !== BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS) {
                            await showLocationConfirm();
                            await NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.LocationServices
                            });
                        }
                    }
                }
                await startGeolocation();
            });
    });
}
</script>
