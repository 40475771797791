// ~/stores/ridesStore.ts
import { format } from "date-fns";
import dayjs from "dayjs";
import _ from "lodash";
import { defineStore } from "pinia";
import type { IDispoSlot, ISlot } from "~/types/slot";
import type { ITerminal } from "~/types/terminal";
import type { ISection, ITripWithLocation } from "~~/backend/types/trip";
import type { ITripHistoryDetail, ITripHistoryLocation, ITripLocation } from "~~/backend/types/tripHistory";

export interface ISectionView {
    id: number;
    content: string;
}

export interface RidesStoreState {
    loading: boolean;
    active: Array<ITripWithLocation>;
    slots: Array<IDispoSlot>;
    sections: Array<ISectionView>;
    locationFocus: Array<ITripHistoryLocation>;
    locationFocusTrip?: ITripHistoryDetail;
}

export const useRidesStore = defineStore("ridesStore", {
    state: (): RidesStoreState => ({
        loading: false,
        active: [],
        slots: [],
        sections: [],
        locationFocus: [],
        locationFocusTrip: undefined
    }),
    actions: {
        async initializeActive(depo: ITerminal) {
            await this.updateActive(depo);
        },
        async updateActive(depo: ITerminal) {
            this.loading = true;
            const { data: rides } = await useAxios().get<Array<ITripWithLocation>>(`api/ride/active/${depo.ST_FLAG}`);
            this.active = _.orderBy(rides, "DISTANCE", ["asc"]);
            this.loading = false;
        },
        initializeSections(sectionsArray: Array<ISection>) {
            this.sections = sectionsArray.map((section) => ({
                id: section.ID,
                content: section.POPIS
            }));
        },
        initializeSlots(slotsArray: Array<IDispoSlot>) {
            this.slots = slotsArray;
        },
        /**
         * Push slots from props to the VIS items
         * @param slots
         */
        pushSlots(slotList: Array<ISlot | IDispoSlot>) {
            const items: IDispoSlot[] = JSON.parse(JSON.stringify(this.slots));

            if (slotList) {
                for (const slot of slotList) {
                    const iy = items.findIndex(
                        (s) =>
                            s.group === slot.group &&
                            dayjs(s.start).isSame(dayjs(slot.start)) &&
                            dayjs(s.end).isSame(dayjs(slot.end))
                    );

                    if ((iy === -1 || iy === 0) && "ID_TRIP" in slot) {
                        items.push({
                            group: slot.group,
                            start: dayjs(slot.start).format("YYYY-MM-DD HH:mm:ss"),
                            end: dayjs(slot.end).format("YYYY-MM-DD HH:mm:ss"),
                            // content: slotItem.slot.ID_TRIP ? "1" : slotItemslot.trips.length.toString(),
                            // trips: slotItem.slot.ID_TRIP ? [slotItem.slot.ID_TRIP] : slot.trips
                            content: "1",
                            trips: [slot.ID_TRIP]
                        });
                    } else if ((iy === -1 || iy === 0) && "trips" in slot && slot.trips) {
                        items.push({
                            group: slot.group,
                            start: dayjs(slot.start).format("YYYY-MM-DD HH:mm:ss"),
                            end: dayjs(slot.end).format("YYYY-MM-DD HH:mm:ss"),
                            content: slot.trips?.length.toString() || "1",
                            trips: slot.trips
                        });
                    } else if ("trips" in slot && slot.trips) {
                        for (const idSlot of slot.trips) {
                            const filteredItem = items[iy];
                            if (!filteredItem.trips || !filteredItem.trips.includes(idSlot)) {
                                if (filteredItem && filteredItem.trips) {
                                    filteredItem.trips = filteredItem.trips.concat(idSlot);
                                    filteredItem.content = filteredItem.trips.length.toString();
                                } else {
                                    filteredItem.trips = [idSlot];
                                }
                            }
                        }
                    } else if (iy && "ID_TRIP" in slot && !!slot.ID_TRIP) {
                        const filteredItem = items[iy];
                        if (!filteredItem.trips || !filteredItem.trips?.includes(slot.ID_TRIP)) {
                            if (filteredItem.trips) {
                                filteredItem.trips = filteredItem.trips.concat(slot.ID_TRIP);

                                filteredItem.content = filteredItem.trips.length.toString();
                            } else {
                                filteredItem.trips = [slot.ID_TRIP];
                            }
                        }
                    } else {
                        throw new Error("Invalid slot item");
                    }
                }
            }
            this.initializeSlots(items);
        },
        updateRide(ride: ITripWithLocation | undefined) {
            if (ride) {
                const finding = this.active.findIndex((active) => active.ID === ride.ID);
                if (finding >= 0) {
                    if (!ride.LOCATION) {
                        ride.LOCATION = [];
                    }

                    ride.UPDATED_ON = format(ride.UPDATED_ON, "yyyy-MM-dd HH:mm:ss");

                    this.active[finding] = ride;
                }
            }
        },
        addRide(ride: ITripWithLocation[]) {
            this.active.push(...ride);
        },
        removeRides(idRides: number[]) {
            for (const idRide of idRides) {
                const activeFinding = this.active.findIndex((active) => active.ID === idRide);
                if (activeFinding >= 0) {
                    this.active.splice(activeFinding, 1);
                }
            }
        },
        addSlot(slot: IDispoSlot) {
            this.slots.push(slot);
        },
        addLocation(location: ITripLocation) {
            const finding = this.active.findIndex((active) => active.ID === location.ID_TRIP);
            if (finding >= 0 && this.active[finding]) {
                this.active[finding].DISTANCE = location.DISTANCE;
                if (this.active[finding] && !this.active[finding].LOCATION) {
                    this.active[finding].LOCATION = [];
                }
                this.active[finding].LOCATION?.push({
                    ACC: location.ACC,
                    ID: location.ID,
                    ID_TRIP: location.ID_TRIP,
                    LAT: location.LAT,
                    LNG: location.LNG,
                    TIME: location.TIME
                });
            }
        },
        updateSlot(slot: IDispoSlot) {
            if (!slot.trips) return;
            for (const tripId of slot.trips) {
                this.removeSlot([tripId]);
                this.addSlot(slot);
            }
        },
        removeSlot(idTrips: number[]) {
            console.log("removeSlot", idTrips);
            console.log("this.slots", this.slots);
            for (const idTrip of idTrips) {
                for (const groupedSlot of this.slots) {
                    if (groupedSlot.trips && groupedSlot.trips.length === 1 && groupedSlot.trips[0] === idTrip) {
                        this.slots.splice(
                            this.slots.findIndex((i) => i === groupedSlot),
                            1
                        );
                        break;
                    }

                    if (groupedSlot.trips) {
                        const index = groupedSlot.trips.indexOf(idTrip);
                        if (index !== -1) {
                            groupedSlot.trips.splice(index, 1);
                            groupedSlot.content = groupedSlot.trips.length.toString();
                            break;
                        }
                    }
                }
            }
        },
        setLoading(state: boolean) {
            this.loading = state;
        },
        setLocationFocus(state: Array<ITripHistoryLocation>) {
            this.locationFocus = state;
        },
        setLocationFocusTrip(state: ITripHistoryDetail | undefined) {
            this.locationFocusTrip = state;
        }
    },

    getters: {
        filteredTrips(): Array<ITripWithLocation> {
            return _.sortBy(this.active, "DISTANCE", ["asc"]);
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRidesStore, import.meta.hot));
}
