
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as confirmBiW6VgRjLgMeta } from "/code/frontend/pages/auth/confirm.vue?macro=true";
import { default as forgotPasswordLP3mVK5bnoMeta } from "/code/frontend/pages/auth/forgotPassword.vue?macro=true";
import { default as loginc8VB5TgY9MMeta } from "/code/frontend/pages/auth/login.vue?macro=true";
import { default as logout7LWq4wN8tuMeta } from "/code/frontend/pages/auth/logout.vue?macro=true";
import { default as registerg9wQl2VM6ZMeta } from "/code/frontend/pages/auth/register.vue?macro=true";
import { default as calendar8PmiHgKBGEMeta } from "/code/frontend/pages/dispatcher/calendar.vue?macro=true";
import { default as indexb1aOb7JQQsMeta } from "/code/frontend/pages/dispatcher/index.vue?macro=true";
import { default as schedulerBdDfF4uBXNMeta } from "/code/frontend/pages/dispatcher/scheduler.vue?macro=true";
import { default as settingsH9oVpsDbs8Meta } from "/code/frontend/pages/dispatcher/settings.vue?macro=true";
import { default as socketio4aRP1qn4cXMeta } from "/code/frontend/pages/dispatcher/socketio.vue?macro=true";
import { default as statisticsyBLBuWl2fiMeta } from "/code/frontend/pages/dispatcher/statistics.vue?macro=true";
import { default as terminal95V4BlukJdMeta } from "/code/frontend/pages/dispatcher/terminal.vue?macro=true";
import { default as translatorgXgqCNd9IxMeta } from "/code/frontend/pages/dispatcher/translator.vue?macro=true";
import { default as waypointsnlb1ywFPkdMeta } from "/code/frontend/pages/dispatcher/waypoints.vue?macro=true";
import { default as docsxix6i6eDTNMeta } from "/code/frontend/pages/docs.vue?macro=true";
import { default as arrivals0xtDO3QpnLMeta } from "/code/frontend/pages/driver/arrivals.vue?macro=true";
import { default as chatHjPKy8IejMMeta } from "/code/frontend/pages/driver/chat.vue?macro=true";
import { default as cmrf7YAUc8g7aMeta } from "/code/frontend/pages/driver/cmr.vue?macro=true";
import { default as historyWu7urRDfxUMeta } from "/code/frontend/pages/driver/history.vue?macro=true";
import { default as profilelhYFa0e2v6Meta } from "/code/frontend/pages/driver/profile.vue?macro=true";
import { default as indexBD9XejVL8yMeta } from "/code/frontend/pages/index.vue?macro=true";
import { default as policycXcT2S6iZkMeta } from "/code/frontend/pages/privacy/policy.vue?macro=true";
import { default as qrjdMJLOkF9vMeta } from "/code/frontend/pages/qr.vue?macro=true";
export default [
  {
    name: "auth-confirm",
    path: "/auth/confirm",
    meta: confirmBiW6VgRjLgMeta || {},
    component: () => import("/code/frontend/pages/auth/confirm.vue")
  },
  {
    name: "auth-forgotPassword",
    path: "/auth/forgotPassword",
    meta: forgotPasswordLP3mVK5bnoMeta || {},
    component: () => import("/code/frontend/pages/auth/forgotPassword.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginc8VB5TgY9MMeta || {},
    component: () => import("/code/frontend/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logout7LWq4wN8tuMeta || {},
    component: () => import("/code/frontend/pages/auth/logout.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerg9wQl2VM6ZMeta || {},
    component: () => import("/code/frontend/pages/auth/register.vue")
  },
  {
    name: "dispatcher-calendar",
    path: "/dispatcher/calendar",
    meta: calendar8PmiHgKBGEMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/calendar.vue")
  },
  {
    name: "dispatcher",
    path: "/dispatcher",
    meta: indexb1aOb7JQQsMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/index.vue")
  },
  {
    name: "dispatcher-scheduler",
    path: "/dispatcher/scheduler",
    meta: schedulerBdDfF4uBXNMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/scheduler.vue")
  },
  {
    name: "dispatcher-settings",
    path: "/dispatcher/settings",
    meta: settingsH9oVpsDbs8Meta || {},
    component: () => import("/code/frontend/pages/dispatcher/settings.vue")
  },
  {
    name: "dispatcher-socketio",
    path: "/dispatcher/socketio",
    meta: socketio4aRP1qn4cXMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/socketio.vue")
  },
  {
    name: "dispatcher-statistics",
    path: "/dispatcher/statistics",
    meta: statisticsyBLBuWl2fiMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/statistics.vue")
  },
  {
    name: "dispatcher-terminal",
    path: "/dispatcher/terminal",
    meta: terminal95V4BlukJdMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/terminal.vue")
  },
  {
    name: "dispatcher-translator",
    path: "/dispatcher/translator",
    meta: translatorgXgqCNd9IxMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/translator.vue")
  },
  {
    name: "dispatcher-waypoints",
    path: "/dispatcher/waypoints",
    meta: waypointsnlb1ywFPkdMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/waypoints.vue")
  },
  {
    name: "docs",
    path: "/docs",
    meta: docsxix6i6eDTNMeta || {},
    component: () => import("/code/frontend/pages/docs.vue")
  },
  {
    name: "driver-arrivals",
    path: "/driver/arrivals",
    meta: arrivals0xtDO3QpnLMeta || {},
    component: () => import("/code/frontend/pages/driver/arrivals.vue")
  },
  {
    name: "driver-chat",
    path: "/driver/chat",
    meta: chatHjPKy8IejMMeta || {},
    component: () => import("/code/frontend/pages/driver/chat.vue")
  },
  {
    name: "driver-cmr",
    path: "/driver/cmr",
    meta: cmrf7YAUc8g7aMeta || {},
    component: () => import("/code/frontend/pages/driver/cmr.vue")
  },
  {
    name: "driver-history",
    path: "/driver/history",
    meta: historyWu7urRDfxUMeta || {},
    component: () => import("/code/frontend/pages/driver/history.vue")
  },
  {
    name: "driver-profile",
    path: "/driver/profile",
    meta: profilelhYFa0e2v6Meta || {},
    component: () => import("/code/frontend/pages/driver/profile.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexBD9XejVL8yMeta || {},
    component: () => import("/code/frontend/pages/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy/policy",
    meta: policycXcT2S6iZkMeta || {},
    component: () => import("/code/frontend/pages/privacy/policy.vue")
  },
  {
    name: "qr",
    path: "/qr",
    meta: qrjdMJLOkF9vMeta || {},
    component: () => import("/code/frontend/pages/qr.vue")
  }
]