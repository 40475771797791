
// @ts-nocheck
import locale__code_frontend_i18n_locales_cs_json from "../i18n/locales/cs.json";
import locale__code_frontend_i18n_locales_en_json from "../i18n/locales/en.json";
import locale__code_frontend_i18n_locales_sk_json from "../i18n/locales/sk.json";
import locale__code_frontend_i18n_locales_pl_json from "../i18n/locales/pl.json";
import locale__code_frontend_i18n_locales_hu_json from "../i18n/locales/hu.json";
import locale__code_frontend_i18n_locales_de_json from "../i18n/locales/de.json";


export const localeCodes =  [
  "cs",
  "en",
  "sk",
  "pl",
  "hu",
  "de"
]

export const localeLoaders = {
  "cs": [{ key: "../i18n/locales/cs.json", load: () => Promise.resolve(locale__code_frontend_i18n_locales_cs_json), cache: true }],
  "en": [{ key: "../i18n/locales/en.json", load: () => Promise.resolve(locale__code_frontend_i18n_locales_en_json), cache: true }],
  "sk": [{ key: "../i18n/locales/sk.json", load: () => Promise.resolve(locale__code_frontend_i18n_locales_sk_json), cache: true }],
  "pl": [{ key: "../i18n/locales/pl.json", load: () => Promise.resolve(locale__code_frontend_i18n_locales_pl_json), cache: true }],
  "hu": [{ key: "../i18n/locales/hu.json", load: () => Promise.resolve(locale__code_frontend_i18n_locales_hu_json), cache: true }],
  "de": [{ key: "../i18n/locales/de.json", load: () => Promise.resolve(locale__code_frontend_i18n_locales_de_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "cs",
      "label": "čeština",
      "files": [
        "/code/frontend/i18n/locales/cs.json"
      ]
    },
    {
      "code": "en",
      "label": "english",
      "files": [
        "/code/frontend/i18n/locales/en.json"
      ]
    },
    {
      "code": "sk",
      "label": "slovenčina",
      "files": [
        "/code/frontend/i18n/locales/sk.json"
      ]
    },
    {
      "code": "pl",
      "label": "polski",
      "files": [
        "/code/frontend/i18n/locales/pl.json"
      ]
    },
    {
      "code": "hu",
      "label": "magyar",
      "files": [
        "/code/frontend/i18n/locales/hu.json"
      ]
    },
    {
      "code": "de",
      "label": "deutch",
      "files": [
        "/code/frontend/i18n/locales/de.json"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "label": "čeština",
    "files": [
      {
        "path": "/code/frontend/i18n/locales/cs.json"
      }
    ]
  },
  {
    "code": "en",
    "label": "english",
    "files": [
      {
        "path": "/code/frontend/i18n/locales/en.json"
      }
    ]
  },
  {
    "code": "sk",
    "label": "slovenčina",
    "files": [
      {
        "path": "/code/frontend/i18n/locales/sk.json"
      }
    ]
  },
  {
    "code": "pl",
    "label": "polski",
    "files": [
      {
        "path": "/code/frontend/i18n/locales/pl.json"
      }
    ]
  },
  {
    "code": "hu",
    "label": "magyar",
    "files": [
      {
        "path": "/code/frontend/i18n/locales/hu.json"
      }
    ]
  },
  {
    "code": "de",
    "label": "deutch",
    "files": [
      {
        "path": "/code/frontend/i18n/locales/de.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
