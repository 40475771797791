import { defineStore } from "pinia";
import { type Socket, io } from "socket.io-client";
import type { ClientToServerEvents, ServerToClientEvents } from "~~/backend/types/socket";

interface State {
    socket: Socket<ServerToClientEvents, ClientToServerEvents>;
    listeners: Map<keyof ServerToClientEvents, ServerToClientEvents[keyof ServerToClientEvents]>;
}

interface _UserMessageObject {
    ID: number;
    USER?: string;
    FULLNAME?: string;
    EMAIL?: string;
    DESCRIPTION?: string;
    LANGUAGE: "cs" | "sk" | "pl" | "de" | "hu" | "en";
    USERRIGHT?: string;
    ID_RIDICI?: number;
    RIDE_ID?: number;
    DELETED_ON?: string;
    TERMINAL?: string;
}

export const useConnectionStore = defineStore("connection", {
    state: (): State => ({
        socket: io(useRuntimeConfig().public.baseUrl as string, {
            withCredentials: true,
            autoConnect: false,
            path: "/api/socket.io",
            extraHeaders: {
                "Access-Control-Allow-Credentials": "true"
            },
            transports: ["websocket"]
        }),
        listeners: new Map<
            keyof ServerToClientEvents,
            (...args: Parameters<ServerToClientEvents[keyof ServerToClientEvents]>) => void
        >() // Keep track of registered listeners,
    }),

    actions: {
        bindEvents() {
            this.socket.on("disconnect", () => {
                this.socket.auth = {};
                this.socket.off();

                for (const [key, callback] of this.listeners) {
                    this.socket.off(key, callback);
                }

                // Clear all listeners if not authenticated
                if (useAuthState().status.value !== "authenticated") {
                    this.listeners.clear();
                }
            });

            this.socket.io.on("reconnect_attempt", (_attempt) => {
                this.socket.auth = {
                    token: useAuthState().token.value // Update the auth object for reconnection
                };
            });
            // Re-register listeners on reconnect
            this.socket.io.on("reconnect", (_attempt) => {
                this.socket.io.removeAllListeners();
                this.listeners.forEach((callback, event) => {
                    this.socket.on(event, callback);
                });
            });
        },

        connect() {
            this.socket.auth = {
                token: useAuthState().token.value // Update the auth object
            };
            this.socket.connect();
        },
        disconnect() {
            this.socket.disconnect();
        },

        addListener<E extends keyof ServerToClientEvents>(event: E, callback: ServerToClientEvents[E]) {
            if (!this.listeners.has(event)) {
                this.listeners.set(event, callback);
                this.socket.on<keyof ServerToClientEvents>(event, callback); // Register listener with the socket
            }
        },

        removeListener(event: keyof ServerToClientEvents) {
            const callback = this.listeners.get(event);
            if (callback) {
                this.socket.off(event, callback); // Remove listener from the socket
                this.listeners.delete(event); // Remove from the map
            }
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useConnectionStore, import.meta.hot));
}
