<template>
    <div>
        <nav class="navbar fixed-top navbar-light bg-light navbar-expand-xl p-0">
            <div id="dispo-header" class="container-fluid">
                <!-- <header class="main-header p-2"> -->
                <a class="navbar-brand" href="/dispatcher">
                    <img src="~/assets/images/metrans_logo.png" height="40px" :title="totalUsersCount.toString()" />
                </a>
                <button
                    class="navbar-toggler p-1 m-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarItems"
                    aria-controls="navbarItems"
                    aria-expanded="false"
                    :aria-label="$t('label.toggleNavigation')"
                >
                    <span class="navbar-toggler-icon" />
                </button>
                <div id="navbarItems" class="collapse navbar-collapse" style="--bs-scroll-height: calc(100dvh - 56px)">
                    <div class="navbar-nav navbar-nav-scroll container-fluid">
                        <div class="navbar-nav mb-2 mb-xl-0 me-xl-auto me-0">
                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <NuxtLink
                                    :to="{ name: 'dispatcher' }"
                                    class="link-underline link-underline-opacity-0"
                                    :title="$t('dispo.dispo')"
                                >
                                    <div class="btn nav-link">
                                        <font-awesome-icon icon="clipboard" class="pe-0" />
                                        <div class="d-xl-none d-inline ps-2">
                                            {{ $t("dispo.dispo") }}
                                        </div>
                                    </div>
                                </NuxtLink>
                            </div>
                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <NuxtLink
                                    :to="{ name: 'dispatcher-calendar' }"
                                    class="link-underline link-underline-opacity-0"
                                    :title="$t('dispo.calendar')"
                                >
                                    <div class="btn nav-link">
                                        <font-awesome-icon icon="calendar-alt" class="pe-0" />
                                        <div class="d-xl-none d-inline ps-2">
                                            {{ $t("dispo.calendar") }}
                                        </div>
                                    </div>
                                </NuxtLink>
                            </div>

                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <NuxtLink
                                    :to="{ name: 'dispatcher-terminal' }"
                                    class="link-underline link-underline-opacity-0"
                                    :title="$t('terminal.settings')"
                                    ><div class="btn nav-link">
                                        <font-awesome-icon icon="truck-loading" class="pe-0" />
                                        <div class="d-xl-none d-inline ps-2">
                                            {{ $t("terminal.settings") }}
                                        </div>
                                    </div>
                                </NuxtLink>
                            </div>

                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <button
                                    :title="$t('label.unfinishedrides')"
                                    class="btn nav-link"
                                    @click="openViewOpenRidesModal"
                                >
                                    <font-awesome-icon icon="file-excel" class="pe-0" />
                                    <div class="d-xl-none d-inline ps-2">
                                        {{ $t("label.unfinishedrides") }}
                                    </div>
                                </button>
                            </div>
                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <button
                                    :title="$t('label.skiptimecmr')"
                                    class="btn nav-link"
                                    @click="openViewOpenTimeSkipCMRModal"
                                >
                                    <font-awesome-icon icon="sort-numeric-up" class="pe-0" />
                                    <div class="d-xl-none d-inline ps-2">
                                        {{ $t("label.skiptimecmr") }}
                                    </div>
                                </button>
                            </div>
                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <button
                                    v-if="userCan.chat"
                                    :title="$t('dispo.chat')"
                                    class="btn nav-link"
                                    @click="openMessagesModal"
                                >
                                    <font-awesome-icon icon="envelope" class="pe-0" />
                                    <span v-if="store.newMessagesCount > 0" class="badge bg-success new-message-count">
                                        {{ store.newMessagesCount }}
                                    </span>
                                    <div class="d-xl-none d-inline ps-2">
                                        {{ $t("dispo.chat") }}
                                    </div>
                                </button>
                            </div>
                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <button
                                    :title="$t('dispo.tripsHistory')"
                                    class="btn nav-link"
                                    @click="openLocationsModal"
                                >
                                    <font-awesome-icon icon="clock-rotate-left" class="pe-0" />

                                    <div class="d-xl-none d-inline ps-2">
                                        {{ $t("dispo.tripsHistory") }}
                                    </div>
                                </button>
                            </div>
                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <button
                                    v-if="ridesStore.locationFocus.length"
                                    :title="$t('dispo.tripsHistory').toString()"
                                    @click="clearLocationFocus"
                                >
                                    <div class="btn nav-link">
                                        <font-awesome-icon icon="map-pin" style="color: red" />
                                    </div>
                                </button>
                            </div>
                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <NuxtLink
                                    :to="{ name: 'dispatcher-settings' }"
                                    class="link-underline link-underline-opacity-0"
                                    :title="$t('dispo.userlist')"
                                >
                                    <div class="btn nav-link">
                                        <font-awesome-icon icon="user" class="pe-0" />
                                        <div class="d-xl-none d-inline ps-2">
                                            {{ $t("dispo.userlist") }}
                                        </div>
                                    </div>
                                </NuxtLink>
                            </div>
                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <NuxtLink
                                    :to="{ name: 'dispatcher-statistics' }"
                                    class="link-underline link-underline-opacity-0"
                                    :title="$t('dispo.statistics')"
                                >
                                    <div class="btn nav-link">
                                        <font-awesome-icon icon="chart-line" class="pe-0" />
                                        <div class="d-xl-none d-inline ps-2">
                                            {{ $t("dispo.statistics") }}
                                        </div>
                                    </div>
                                </NuxtLink>
                            </div>
                            <div
                                v-if="userCan.viewDispo"
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <NuxtLink
                                    :to="{ name: 'dispatcher-waypoints' }"
                                    class="link-underline link-underline-opacity-0"
                                    :title="$t('dispo.waypoints')"
                                >
                                    <div class="btn nav-link">
                                        <font-awesome-icon icon="tv" class="pe-0" />
                                        <div class="d-xl-none d-inline ps-2">
                                            {{ $t("dispo.waypoints") }}
                                        </div>
                                    </div>
                                </NuxtLink>
                            </div>
                            <div
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <NuxtLink
                                    v-if="userCan.manageScheduler"
                                    :to="{ name: 'dispatcher-scheduler' }"
                                    class="link-underline link-underline-opacity-0"
                                    :title="$t('dispo.scheduler')"
                                >
                                    <div class="btn nav-link">
                                        <font-awesome-icon icon="clipboard-check" class="pe-0" />
                                        <div class="d-xl-none d-inline ps-2">
                                            {{ $t("dispo.scheduler") }}
                                        </div>
                                    </div>
                                </NuxtLink>
                            </div>
                            <div
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <NuxtLink
                                    v-if="userCan.manageRedis"
                                    class="link-underline link-underline-opacity-0"
                                    :to="{ name: 'dispatcher-socketio' }"
                                    title="Socket.IO"
                                >
                                    <div class="btn nav-link">
                                        <font-awesome-icon icon="tower-cell" class="pe-0" />
                                        <div class="d-xl-none d-inline ps-2">Socket.IO</div>
                                    </div>
                                </NuxtLink>
                            </div>
                        </div>
                        
                        <div class="navbar-nav">
                            <div class="nav-item align-self-center">
                                <DispatcherTerminalSwitcher />
                            </div>
                        </div>
                        <div class="navbar-nav ms-0 ms-xl-auto">
                            <div class="row">
                                <div
                                    :class="[
                                        'btn-group',
                                        'col',
                                        'd-none',
                                        'd-xl-block',
                                        'p-0',
                                        'nav-item',
                                        'border',
                                        'rounded-2'
                                    ]"
                                >
                                    <button
                                        id="dropdownMenuButton"
                                        class="btn nav-link"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <font-awesome-icon icon="ellipsis-v" class="pe-0" />
                                    </button>
                                    <div :class="['dropdown-menu', 'dropdown-menu-end']">
                                        <div>
                                            <a
                                                class="dropdown-item"
                                                data-bs-toggle="modal"
                                                data-bs-target="#languageModal"
                                                >{{ $t("user.change_language") }}</a
                                            >
                                        </div>
                                        <div>
                                            <a class="dropdown-item" @click="openPasswordModal">{{
                                                $t("user.change_password")
                                            }}</a>
                                        </div>
                                        <div>
                                            <a class="dropdown-item" @click="logout">{{ $t("auth.logout") }}</a>
                                        </div>
                                        <div>
                                            <div class="dropdown-divider" />
                                        </div>
                                        <div>
                                            <a class="dropdown-item disabled"
                                                >{{ config.public.packageVersion }} | WS:
                                                <font-awesome-icon
                                                    :icon="connectionStore.socket.connected ? 'check' : 'xmark'"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="navbar-nav d-xl-none mb-2">
                            <div
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <a class="nav-link" data-bs-toggle="modal" data-bs-target="#languageModal">{{
                                    $t("user.change_language")
                                }}</a>
                            </div>
                            <div
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <a class="nav-link" @click="openPasswordModal">{{ $t("user.change_password") }}</a>
                            </div>
                            <div
                                class="nav-item border rounded-2"
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                aria-controls="navbarItems"
                            >
                                <div>
                                    <a class="nav-link" @click="logout">{{ $t("auth.logout") }}</a>
                                </div>
                            </div>
                            <div class="nav-item border rounded-2">
                                <div class="divider" />
                            </div>
                            <div class="nav-item">
                                <div class="disabled">
                                    {{ config.public.packageVersion }} | WS:
                                    <font-awesome-icon :icon="connectionStore.socket.connected ? 'check' : 'xmark'" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- overview of rides and terminations -->

                <!-- release of the consignment note out of time order -->

                <!-- <button
                        class="btn"
                        data-bs-toggle="tooltip"
                        :title="$t('dispo.layout').toString()"
                        @click="$root.$emit('layout.toggle')"
                    >
                        <font-awesome-icon icon="tv" />
                    </button> -->
            </div>
        </nav>
        <!--  -->
        <main
            :class="[
                'ms-0',
                'me-0',
                'ps-0',
                'pe-0',
                'vw-100',
                'min-vw-100',
                'min-vh-100',
                'vh-100',
                'main-body-frame',
                'hidden-scroll'
            ]"
        >
            <slot />
        </main>
        <DispatcherMessagesModal ref="messagesModal" />
        <DispatcherTripsHistoryModal ref="locationsModal" />
        <CommonVersionCheck />
        <DispatcherViewOpenRidesModal ref="viewOpenRidesModal" />
        <DispatcherViewOpenTimeSkipCMRModal ref="viewOpenTimeSkipCMRModal" />

        <CommonModalComponent
            id="passwordModal"
            ref="passwordModal"
            :no-footer="true"
            :title="$t('user.change_password')"
        >
            <CommonChangePassword :use-token="false" />
        </CommonModalComponent>

        <CommonModalComponent id="languageModal" :title="$t('user.change_language')" :save="changeLanguage">
            <div>
                <div class="div-control-group">
                    <label>{{ $t("user.language") }}</label>
                    <select v-model="language" class="div-control">
                        <option v-for="localeItem in locales" :key="localeItem.code" :value="localeItem.code">
                            {{ localeItem.label }}
                        </option>
                    </select>
                </div>
            </div>
        </CommonModalComponent>
    </div>
</template>

<script setup lang="ts">
import type ModalComponent from "~/components/common/ModalComponent.vue";
import type MessagesModal from "~/components/dispatcher/MessagesModal.vue";
import type TripsHistoryModal from "~/components/dispatcher/TripsHistoryModal.vue";
import type ViewOpenRidesModal from "~/components/dispatcher/ViewOpenRidesModal.vue";
import type ViewOpenTimeSkipCMRModal from "~/components/dispatcher/ViewOpenTimeSkipCMRModal.vue";
import type { ITerminal } from "~/types/terminal";

const userCan = useCan();
const passwordModal = ref<InstanceType<typeof ModalComponent>>();
const messagesModal = ref<InstanceType<typeof MessagesModal>>();
const locationsModal = ref<InstanceType<typeof TripsHistoryModal>>();
const languageModal = ref<InstanceType<typeof ModalComponent>>();
const viewOpenRidesModal = ref<InstanceType<typeof ViewOpenRidesModal>>();
const viewOpenTimeSkipCMRModal = ref<InstanceType<typeof ViewOpenTimeSkipCMRModal>>();

const config = useRuntimeConfig();
const { t, locale, setLocale, setLocaleCookie, locales, loadLocaleMessages } = useI18n();
const axios = useAxios();
const toast = useToast();
const connectionStore = useConnectionStore();

const store = useStore();
const ridesStore = useRidesStore();
const { status: authStatus, data: authData } = useAuth();

/**
 * New messages count
 */

const language = ref<"cs" | "en" | "sk" | "pl" | "hu" | "de" | null>(null);
const totalUsersCount = ref(0);

/**
 * Before the components mount
 */
onBeforeMount(async () => {
    // Connect to WS

    /**
     * Connect to WS whenever the user logs in
     */
    watch(
        () => authStatus.value,
        () => {
            onMountedAction();
        }
    );

    /**
     * Switch language if logged in
     */
    if (authStatus.value === "authenticated" && authData.value) {
        if (locale !== authData.value?.language) {
            await loadLocaleMessages(authData.value?.language);
            await setLocale(authData.value?.language);
            setLocaleCookie(authData.value?.language);
        }
    }
});

/**
 * get new messages
 */
if (store.terminal) {
    loadNewMessagesCount(store.terminal.ST_FLAG);
}
store.loadTerminals().then(() => {
    if (authData.value && authData.value !== null && authData.value !== undefined) {
        const finding =
            store.terminals.find((terminal: ITerminal) => terminal.ST_FLAG === authData.value?.terminal) ||
            store.terminals.find((terminal: ITerminal) => terminal.ST_FLAG === "1");
        if (finding) {
            store.setActiveTerminal(finding);
        }
    }
});

/**
 * Enable tooltips
 */
onMounted(() => {
    onMountedAction();
});

function onMountedAction() {
    try {
        if (authData.value) {
            language.value = authData.value?.language;
        }
    } catch (err) {
        console.error(err);
    }
}

watch(
    () => connectionStore.socket.connected,
    async (newSocket) => {
        if (newSocket) {
            onMountedAction();
        }
    },
    { deep: true }
);

/**
 * If terminal changes
 * @param terminal New terminal
 */

watch(
    () => store.terminal,
    (terminal: ITerminal | null, oldTerminal: ITerminal | null) => {
        if (terminal) {
            if (oldTerminal) {
                connectionStore.socket.emit("dispo.terminal.leave", oldTerminal.ST_FLAG);
            }
            ridesStore.initializeActive(terminal);
            connectionStore.socket.emit("dispo.terminal.join", terminal.ST_FLAG);
            loadNewMessagesCount(terminal.ST_FLAG);
        }
    },
    { deep: true }
);

watch(
    () => connectionStore.socket.connected,
    (connected) => {
        if (connected && store.terminal) {
            connectionStore.socket.emit("dispo.terminal.join", store.terminal.ST_FLAG);
        }
    }
);

/**
 * api call for new messages terminal dispatcher
 */
function loadNewMessagesCount(terminal: string) {
    axios.get("/api/chat/unread/" + terminal).then((messages) => {
        store.setNewMessagesCount(messages.data.length);
    });
}

/**
 * Logout
 */
async function logout() {
    // $('[data-bs-toggle="tooltip"]').tooltip("hide");
    await navigateTo({ name: "auth-logout" });
}

/**
 * Open messages modal
 */
function openMessagesModal() {
    messagesModal.value?.showModal();
}

function openLocationsModal() {
    locationsModal.value?.showModal();
}

/**
 * Open messages modal
 */
function openViewOpenRidesModal() {
    viewOpenRidesModal.value?.showModal();
}

/**
 * Open messages modal
 */
function openViewOpenTimeSkipCMRModal() {
    viewOpenTimeSkipCMRModal.value?.showModal();
}

/**
 * Password change modal
 */
function openPasswordModal() {
    passwordModal.value?.showModal();
}

function clearLocationFocus() {
    ridesStore.setLocationFocus([]);
    ridesStore.setLocationFocusTrip(undefined);
}

function changeLanguage() {
    axios
        .patch("/api/auth/update/user", {
            LANGUAGE: language.value
        })
        .then(async () => {
            if (!language.value) {
                return;
            }
            await loadLocaleMessages(language.value);
            await setLocale(language.value);
            setLocaleCookie(language.value);
            toast.success(t("savestatus.success").toString());
            languageModal.value?.closeModal();
        });
}
</script>

<style lang="scss">
@import "~/assets/scss/pages/dispo";

.new-message-count {
    position: absolute !important;
    top: 10px !important;
}

.navbar-collapse.show {
    height: calc(100dvh - 56px);
}

.navbar-nav.navbar-nav-scroll.container-fluid {
    @media (max-width: 1199px) {
        height: calc(100dvh - 56px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }
    // margin-bottom: calc(env(safe-area-inset-bottom) + env(safe-area-inset-top) + 56px) !important;
    transition: height 0.35s ease;
}
</style>
