<template>
    <div>
        <nav class="navbar navbar-expand-sm fixed-bottom main-menu">
            <ul class="navbar-nav w-100 justify-content-center main-menu-wrapper flex-nowrap">
                <li class="nav-item flex-fill">
                    <NuxtLink to="/" :prefetch="true">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="tasks" />
                            <div class="main-menu-item-text">{{ $t("actions.header") }}</div>
                        </div>
                    </NuxtLink>
                </li>
                <li v-if="store.activeRide" class="nav-item flex-fill">
                    <NuxtLink :to="{ name: 'driver-cmr' }">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="file" />
                            <div class="main-menu-item-text">
                                {{
                                    // authStatus === "authenticated" && authData.value && authData.value?.driver && authData.value?.driver.metrans
                                    authStatus === "authenticated" &&
                                    authData?.id &&
                                    authData?.driver &&
                                    authData?.driver.ciziTahac
                                        ? $t("cmr.header")
                                        : $t("cmr.containers")
                                }}
                            </div>
                        </div>
                    </NuxtLink>
                </li>

                <li
                    v-if="
                        authStatus === 'authenticated' && authData?.id && authData?.driver && authData?.driver.ciziTahac
                    "
                    class="nav-item flex-fill"
                >
                    <NuxtLink :to="{ name: 'driver-arrivals' }">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="history" />
                            <div class="main-menu-item-text">{{ $t("label.history") }}</div>
                        </div>
                    </NuxtLink>
                </li>
                <li
                    v-if="
                        authStatus === 'authenticated' &&
                        authData?.id &&
                        authData?.driver &&
                        !authData?.driver.ciziTahac
                    "
                    class="nav-item flex-fill"
                >
                    <NuxtLink :to="{ name: 'driver-history' }">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="history" />
                            <div class="main-menu-item-text">{{ $t("otherRides.header") }}</div>
                        </div>
                    </NuxtLink>
                </li>
                <li class="nav-item flex-fill">
                    <NuxtLink :to="{ name: 'driver-chat' }">
                        <div class="main-menu-item">
                            <font-awesome-icon icon="comment-dots" />
                            <div class="main-menu-item-text">
                                {{ $t("chat.header") }}
                                <span v-if="store.newMessagesCount > 0" class="badge bg-success">{{
                                    store.newMessagesCount
                                }}</span>
                            </div>
                        </div>
                    </NuxtLink>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
import type { ChatMessageReceiving } from "~~/backend/types/socket";

const { status: authStatus, data: authData } = useAuth();
const { t } = useI18n();
const route = useRoute();
const store = useStore();
const toast = useToast();
const connectionStore = useConnectionStore();
const listeners = {
    "chat.message": chatMessageListener
};

onMounted(() => {
    if (route.name !== "driver-chat") {
        handleListeners("on", listeners);
    }
});

watch(
    () => route.name,
    (_newValue, oldValue) => {
        if (oldValue === "driver-chat") {
            // remove old listener from driver-chat page - only one listener is allowed
            connectionStore.removeListener("chat.message");
            handleListeners("on", listeners);
        }
    }
);

onUnmounted(() => {
    if (authStatus.value !== "authenticated") {
        handleListeners("off", listeners);
    }
});

function chatMessageListener({ message }: ChatMessageReceiving) {
    toast.success(`${t("label.newMessage").toString()}: ${message.content}`);

    if (route.name !== "driver-chat") {
        store.setNewMessagesCount(store.newMessagesCount + 1);
    }
}
</script>
