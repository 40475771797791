<template>
    <div>
        <MobileContainersAppHeader />
        <div :class="['content-body', 'has-bottom-navbar']">
            <slot />
        </div>

        <MobileMenu v-if="authStatus === 'authenticated'" />
    </div>
</template>

<script setup lang="ts">
const { status: authStatus, data: authData } = useAuth();
const axios = useAxios();
const store = useStore();
const { $apm } = useNuxtApp();
const toast = useToast();
const { t } = useI18n();

onMounted(() => {
    if (authStatus.value === "authenticated") {
        initialize();
    }
});

async function initialize() {
    if (authData.value) {
        $apm.setUserContext({
            id: authData.value?.id,
            username: authData.value?.user,
            email: authData.value?.email
        });

        await store.loadActiveRide(true);

        axios
            .get("/api/chat/unread/driver")
            .then((result) => {
                store.setNewMessagesCount(result.data.length);
                if (result.data.length) {
                    for (const messageObj of result.data) {
                        toast.success(`${t("label.newMessage").toString()}: ${messageObj.MESSAGE}`);
                    }
                }
            })
            .catch((e) => {
                $apm.captureError(e);
            });
    }
}
</script>
