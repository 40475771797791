import type { ServerToClientEvents } from "~~/backend/types/socket";

export function handleListeners<E extends keyof ServerToClientEvents = keyof ServerToClientEvents>(
    action: "on" | "off",
    listeners: Record<E, ServerToClientEvents[E]>
) {
    const connectionStore = useConnectionStore();
    for (const key of Object.keys(listeners) as E[]) {
        if (action === "on") {
            connectionStore.addListener(key, listeners[key]);
        } else {
            connectionStore.removeListener(key);
        }
    }
}
